import styled from "@emotion/styled"
import { graphql } from "gatsby"
import React from "react"

import Layout from "../components/layouts"
import SEO from "../components/seo"
import { useTranslationContext } from "../context/TranslationContext"
import ContentModules from "../components/Page/ContentModules/ContentModules"

const Blog = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  margin: 40px 10px;
  color: ${(props) => props.theme.colors.dark};
  a {
    color: ${(props) => props.theme.colors.dark};
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    width: 844px;
    margin: 40px auto;
  }
`

const Title = styled.div`
  font-size: 38px;
  text-align: center;
  line-height: 38px;

  ${(props) => props.theme.margin.x};
  ${(props) => props.theme.margin.y};
`

const BlogPostTemplate = ({ data }) => {
  let { post } = data
  const { translationContext } = useTranslationContext()

  return (
    <Layout almostWhite>
      <SEO
        metaImage={
          post.featuredImage &&
          post.featuredImage.gatsbyImageData.images.fallback.src
        }
        title={post.title}
        canonicals={translationContext.canonicals}
      />
      <Blog>
        <Title>{post.title}</Title>
        <ContentModules
          allModules={post.module}
          sx={{
            ".hero": {
              height: "100%",
              marginY: "6px",
            },
            ".rich-text": {
              padding: "0px",
              marginY: "20px",
              display: "block",
              columnCount: [0, "2"],
              columnGap: "20px",
              lineHeight: "1.2",

              "h1, h2, h3, blockquote": {
                columnSpan: "all",
                marginBottom: "30px",
              },
              blockquote: {
                marginY: "60px",
                p: {
                  textAlign: "center",
                  fontStyle: "italic",
                  fontSize: "38px",
                },
              },
              b: {
                display: "block",
              },
              p: {
                whiteSpace: "normal",
                fontSize: "18px",
              },
            },
            ".slider": {
              maxHeight: "585px",
              marginY: "20px",
              ".asset": {
                "&.vertical": {
                  flexBasis: ["90%", "60%"],
                },
              },
            },
            ".tilesection": {
              position: "relative",
              marginY: "10px",

              ".items": {
                gap: "10px",
                ".tile-title": {
                  bottom: 0,
                  padding: "8px",
                },
              },
            },
          }}
        />
      </Blog>
    </Layout>
  )
}

export default BlogPostTemplate

export const query = graphql`
  query BlogPostQuery($slug: String!, $locale: String!) {
    post: contentfulBlogPost(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      ...BlogPost
    }
  }
`
